import React from "react";

const Circle = () => {
    return (
        <svg
            width="250"
            height="250"
            viewBox="0 0 386 387"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="rotate"
        >
            <g clipPath="url(#clip0_107_79)">
                <path
                    d="M49.8794 223.373C44.8725 199.631 45.873 175.016 52.7901 151.759C59.7072 128.503 72.3219 107.342 89.4898 90.196"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="17.7107"
                />
                <path
                    d="M76.5965 44.2133C110.165 18.1231 151.556 4.12496 194.07 4.48486C236.583 4.84476 277.731 19.5416 310.853 46.1965"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="8.85563"
                />
                <path
                    d="M360.155 106.503C378.426 141.795 385.096 181.953 379.215 221.256C373.333 260.56 355.201 297.006 327.4 325.405C299.599 353.803 263.546 372.705 224.376 379.42C185.206 386.135 144.916 380.32 109.244 362.804"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="8.85563"
                />
                <path
                    d="M38.7091 116.647C52.9474 88.0908 74.8574 64.0642 101.983 47.2604C129.109 30.4565 160.379 21.5395 192.288 21.5087C224.197 21.4779 255.485 30.3346 282.643 47.086C309.801 63.8375 331.758 87.8217 346.051 116.35"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="6.64168"
                />
                <path
                    d="M364.124 186.691C365.161 213.62 359.848 240.416 348.614 264.912C337.38 289.408 320.541 310.918 299.458 327.703C278.374 344.488 253.638 356.078 227.247 361.536C200.856 366.994 173.552 366.166 147.54 359.121"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="6.64168"
                />
                <path
                    d="M139.458 57.5651C167.878 46.4682 199.064 44.5715 228.619 52.1423C258.174 59.7131 284.607 76.3695 304.192 99.7631"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="17.7107"
                />
                <path
                    d="M328.575 193.488C328.575 229.594 314.233 264.222 288.703 289.753C263.174 315.285 228.548 329.628 192.444 329.628C156.34 329.628 121.715 315.285 96.1852 289.753C70.6557 264.222 56.3134 229.594 56.3134 193.488C56.3134 157.381 70.6557 122.753 96.1852 97.2219C121.715 71.6907 156.34 57.3474 192.444 57.3474C228.548 57.3474 263.174 71.6907 288.703 97.2219C314.233 122.753 328.575 157.381 328.575 193.488Z"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="15.4974"
                />
                <path
                    d="M110.221 104.935C117.719 97.9565 126.074 91.9605 135.086 87.0916"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="11.0696"
                />
                <path
                    d="M368.613 186.566C370.04 223.778 359.646 260.486 338.922 291.426C318.197 322.365 288.207 345.947 253.251 358.788C218.296 371.629 180.172 373.071 144.347 362.906C108.522 352.74 76.8365 331.491 53.8348 302.204"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="3.32096"
                />
                <path
                    d="M94.4997 334.424C80.4446 324.668 67.9256 312.868 57.3571 299.414"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="6.64168"
                />
                <path
                    d="M34.7087 114.633C49.3328 85.3043 71.8434 60.6327 99.7129 43.389C127.582 26.1453 159.707 17.0123 192.48 17.0155C225.253 17.0187 257.376 26.1581 285.242 43.4073C313.108 60.6565 335.614 85.3325 350.232 114.664"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="3.32096"
                />
                <path
                    d="M248.665 327.74C225.34 337.49 199.88 341.005 174.786 337.938C149.692 334.872 125.827 325.329 105.537 310.249"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="17.7107"
                />
                <path
                    d="M336.315 216.528C331.408 246.936 316.991 275.007 295.135 296.711"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="17.7107"
                />
                <path
                    d="M202.619 73.0094C225.695 74.9569 247.723 83.5002 266.077 97.6204"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="11.0696"
                />
                <path
                    d="M311.9 210.871C308.004 237.35 295.418 261.783 276.122 280.33"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="11.0696"
                />
                <path
                    d="M135.927 299.967C117.811 290.363 102.441 276.302 91.2646 259.112"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="11.0696"
                />
                <path
                    d="M315.879 211.505C312.185 236.569 300.946 259.914 283.659 278.435C266.372 296.956 243.856 309.774 219.105 315.185C194.355 320.596 168.543 318.343 145.105 308.725C121.666 299.108 101.711 282.582 87.8946 261.346"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="4.42784"
                />
                <path
                    d="M254.787 97.8553C269.149 107.234 281.209 119.732 290.069 134.419C298.929 149.107 304.36 165.604 305.958 182.682C307.557 199.759 305.281 216.978 299.3 233.054C293.32 249.13 283.788 263.649 271.417 275.53"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="8.85567"
                />
                <path
                    d="M114.731 109.9C128.223 97.3278 144.563 88.2162 162.35 83.3472C180.137 78.4781 198.84 77.9969 216.854 81.9449"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="8.85567"
                />
                <path
                    d="M277.796 218.216C271.39 240.201 256.747 258.867 236.919 270.323C217.091 281.78 193.607 285.145 171.36 279.716C149.113 274.287 129.819 260.484 117.497 241.181C105.175 221.879 100.776 198.567 105.216 176.102"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="6.6418"
                />
                <path
                    d="M128.013 132.048C140.339 119.083 156.289 110.138 173.779 106.382C191.269 102.625 209.486 104.232 226.048 110.992C242.61 117.753 256.748 129.352 266.614 144.274C276.48 159.197 281.615 176.748 281.348 194.635"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="6.6418"
                />
                <path
                    d="M109.916 197.451C109.223 183.69 111.983 169.974 117.943 157.551C123.904 145.128 132.876 134.393 144.044 126.322C155.211 118.251 168.22 113.101 181.885 111.34C195.551 109.579 209.44 111.263 222.289 116.239C235.138 121.214 246.539 129.324 255.454 139.83C264.37 150.336 270.517 162.904 273.336 176.391C276.155 189.878 275.557 203.857 271.596 217.054C267.635 230.251 260.436 242.248 250.656 251.954"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="6.64177"
                />
                <path
                    d="M164.358 154.779C169.812 150.8 176.049 148.024 182.656 146.636C189.263 145.248 196.09 145.278 202.684 146.725C209.278 148.172 215.49 151.003 220.909 155.03C226.327 159.058 230.829 164.19 234.116 170.087C237.403 175.984 239.4 182.512 239.976 189.239C240.551 195.965 239.692 202.738 237.455 209.107C235.217 215.477 231.653 221.299 226.997 226.188C222.342 231.078 216.701 234.923 210.448 237.469"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="6.64181"
                />
                <path
                    d="M168.911 234.764C159.714 229.539 152.515 221.404 148.447 211.639C144.38 201.874 143.675 191.033 146.443 180.823"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="6.64181"
                />
                <path
                    d="M166.948 158.376C175.195 152.351 185.308 149.439 195.496 150.155C205.684 150.871 215.29 155.169 222.613 162.289C229.936 169.408 234.503 178.89 235.506 189.054C236.509 199.218 233.882 209.409 228.092 217.822C222.301 226.235 213.72 232.328 203.869 235.021C194.017 237.714 183.53 236.834 174.264 232.537C164.999 228.239 157.554 220.802 153.246 211.541C148.939 202.28 148.048 191.794 150.73 181.939"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="3.32081"
                />
                <path
                    d="M176.796 171.158C180.192 168.756 184.094 167.168 188.202 166.515C192.31 165.862 196.513 166.163 200.486 167.394"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="5.53457"
                />
                <path
                    d="M214.412 177.374C218.026 182.357 219.839 188.421 219.551 194.57C219.263 200.72 216.892 206.588 212.827 211.211"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="5.53457"
                />
                <path
                    d="M196.81 220.073C192.087 220.84 187.244 220.345 182.774 218.636C178.304 216.928 174.365 214.067 171.358 210.344"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="5.53457"
                />
                <path
                    d="M165.748 198.011C164.794 192.6 165.506 187.026 167.79 182.029"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="5.53457"
                />
                <path
                    d="M161.764 198.668C160.846 193.416 161.291 188.017 163.056 182.986C164.821 177.956 167.848 173.462 171.846 169.936C175.845 166.409 180.681 163.968 185.893 162.845C191.104 161.723 196.517 161.956 201.613 163.523"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="3.32089"
                />
                <path
                    d="M178.111 173.052C183.075 169.53 189.161 167.956 195.211 168.628C201.26 169.3 206.852 172.172 210.922 176.697C214.993 181.223 217.259 187.086 217.288 193.173C217.318 199.26 215.111 205.145 211.085 209.71"
                    stroke="#7E7E7E"
                    strokeOpacity="0.27"
                    strokeWidth="3.32085"
                />
                <path
                    d="M340.061 193.3C340.061 232.448 324.51 269.992 296.828 297.674C269.147 325.355 231.603 340.906 192.455 340.906C153.308 340.906 115.764 325.355 88.0821 297.674C60.4006 269.992 44.8493 232.448 44.8493 193.3C44.8493 154.153 60.4006 116.609 88.0821 88.9274C115.764 61.2459 153.308 45.6946 192.455 45.6946C231.603 45.6946 269.147 61.2459 296.828 88.9274C324.51 116.609 340.061 154.153 340.061 193.3Z"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="2.21397"
                />
                <path
                    d="M248.552 327.088C229.774 334.961 209.548 338.792 189.192 338.333C168.835 337.873 148.803 333.134 130.399 324.422"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="3.32084"
                />
                <path
                    d="M316.819 118.601C331.771 143.492 338.909 172.298 337.31 201.291"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="3.32084"
                />
                <path
                    d="M47.4893 198.768C46.2383 165.586 56.4089 132.979 76.3026 106.392"
                    stroke="#00AD6F"
                    strokeOpacity="0.29"
                    strokeWidth="3.32084"
                />
            </g>
            <defs>
                <clipPath id="clip0_107_79">
                    <rect width="386" height="386.524" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Circle;
